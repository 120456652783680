<template>
    <main>
        <page-header title="Servicios">
            <template #page-icon>
                <i class="fas fa-list"></i>
            </template>
        </page-header>
        <div class="container-fluid">
            <div class="card mt-n10">
                <div class="card-body">
                    <data-grid :data="pagination"
                               @changePage="changePage($event)"
                               @perPageChange="perPageChange($event)"
                               @search="search($event)">
                        <template #grid>
                            <grid-table>
                                <template #head>
                                    <grid-cell-header v-for="(h,i) in headers" :key="`h_${i}`">{{h}}</grid-cell-header>
                                </template>
                                <template #body>
                                    <grid-row v-for="(service, i) in pagination.data" :key="`d_${i}`">
                                        <grid-cell>{{service.codigo}}</grid-cell>
                                        <grid-cell>{{service.descripcion}}</grid-cell>
                                        <grid-cell>{{ (service.agrupacion || {}).descripcion }}</grid-cell>
                                        <grid-cell class="text-center">
                                            <div class="custom-control custom-checkbox">
                                                <input class="custom-control-input"
                                                       :id="`check_${i}`"
                                                       :checked="service.activo === 'S'"
                                                       type="checkbox">
                                                <label class="custom-control-label" :for="`check_${i}`">Activo</label>
                                            </div>
                                        </grid-cell>
                                        <grid-cell>
                                            <router-link class="btn btn-transparent-dark btn-icon btn-sm"
                                            :to="{name : 'services.edit', params : {id : service.id}}">
                                                <i class="fa fa-edit"></i>
                                            </router-link>
                                        </grid-cell>
                                    </grid-row>
                                </template>
                            </grid-table>
                        </template>
                        <template #action-button>
                            <router-link class="btn btn-sm btn-info shadow-sm mb-2" :to="{name: 'services.create'}">
                                <i class="fas fa-plus"></i> Nuevo Servicio
                            </router-link>
                        </template>
                    </data-grid>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import PageHeader from "../../../components/layouts/content/page-header";
import DataGrid from "../../../components/common/utilities/DataGrid/DataGrid";
import ServicioService from "../../../services/servicioService";
import Swal from 'sweetalert2/src/sweetalert2';
import GridTable from "../../../components/common/utilities/DataGrid/GridTable";
import GridCellHeader from "../../../components/common/utilities/DataGrid/GridCellHeader";
import GridRow from "../../../components/common/utilities/DataGrid/GridRow";
import GridCell from "../../../components/common/utilities/DataGrid/GridCell";

export default {
    components: {GridCell, GridRow, GridCellHeader, GridTable, DataGrid, PageHeader},
    data ()  {
        return {
            headers : ['Codigo', 'Descripcion', 'Agrupacion', 'Activo', ''],
            pagination : {
                data : [],
                current_page : 1,
                per_page : 5,
                page : 1,
                search : '',
                total : 0
            }
        }
    },
    created() {
      this.index();
    },
    methods : {
        async index(){
            try {
                this.LoaderSpinnerShow();

                const response = (await ServicioService.index(this.pagination.search, this.pagination))?.data;

                this.pagination.data = response.data;
                this.pagination.current_page = response.current_page;
                this.pagination.total = response.total;

                this.LoaderSpinnerHide();
            }catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Swal.fire('Ocurrio un error al procesar la solicitud', '', 'error');
            }
        },
        changePage(page) {
            this.pagination.page = page;
            this.index();
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.index();
        },
        search(query){
            this.pagination.search = query;
            this.pagination.page = 1;
            this.index();
        }
    }
}
</script>

<style scoped>

</style>
